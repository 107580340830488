/**
 * 业务无关的工具函数包
 *
 */
import { CommonObjectType, SpellConfigParamTypeEnum, SpellConfigParamTypeOptionForSelection, SpellConfigType } from '@/types';
import moment from 'moment/moment';

import 'moment/locale/zh-cn';
import _, { groupBy, map, mapValues } from 'lodash';

moment.locale('zh');

export function getAllParamNameList(text: string) {
  const keywords: string[] = [];
  let match;
  const regexp = /{{([^{}]+)}}/g;

  while ((match = regexp.exec(text)) !== null) {
    const key = match[0].substring(2, match[0].length - 2);
    if (!keywords.includes(key)) {
      keywords.push(key);
    }
  }

  return keywords;
}

function replaceParam(text: string, values: Record<string, string>) {
  let replacedText = text;

  let keys = Object.keys(values);
  for (const key of keys) {
    replacedText = replacedText.replace(new RegExp('{{' + key + '}}', 'g'), values[key]);
  }

  return replacedText;
}

export function createPrompt(config: SpellConfigType, values: Record<string, string | null>) {
  const replacedValues = JSON.parse(JSON.stringify(values));

  // 预处理。 由于selection的value会填入label（为了保密），因此需要先将{label,label}还原为{label,value}模式
  config.parameters.forEach(param => {
    if (replacedValues[param.name] === undefined) {
      // 如果输入values中没有，跳过
      return;
    }

    if (param.type === SpellConfigParamTypeEnum.Selection) {
      const labelValue = replacedValues[param.name];
      // 将labelValue，找到真正的value
      let realValue = labelValue;

      const optionJsonList = JSON.parse(param.typeOption || '[]');
      const foundOption = optionJsonList.find((v: string | SpellConfigParamTypeOptionForSelection) => {
        if (typeof v === 'string') {
          return v === labelValue;
        } else if (typeof v === 'object') {
          return v.label === labelValue || v.value === labelValue;
        }
      });

      if (!foundOption) {
        throw `参数${param.name}无法找到对应的选项'${labelValue}' （历史记录和现有配置不匹配）`;
      }

      if (typeof foundOption === 'object' && foundOption.label === labelValue) {
        realValue = foundOption.value;
      }

      replacedValues[param.name] = realValue;
    }
  });

  let promptString = config.pattern;

  const regexp = /【([^【】])+】/g;
  let match;
  while ((match = regexp.exec(config.pattern)) !== null) {
    const optionText = match[0];

    const optionalNameList = getAllParamNameList(optionText);

    // optional中的每个参数，都填写了，才能使用这段文本
    let isAllFilled = true;
    for (const name of optionalNameList) {
      const optionValue = (replacedValues[name] || '').trim();
      if (optionValue === '') {
        isAllFilled = false;
        break;
      }
    }

    if (isAllFilled) {
      // 用值替换参数，并去掉【】
      let replacedOptionText = replaceParam(optionText, replacedValues);
      replacedOptionText = replacedOptionText.substring(1, replacedOptionText.length - 1);
      promptString = promptString.replace(optionText, replacedOptionText);
    } else {
      // 移除【】的内容
      promptString = promptString.replace(optionText, '');
    }
  }

  // 下面的正则替换中，对于包含特殊字符的 $ 会失效，后期改下
  for (const param of config.parameters) {
    if (replacedValues[param.name]) {
      promptString = promptString.replace(new RegExp('{{' + param.name + '}}', 'g'), replacedValues[param.name]);
    }
  }

  // 含有特殊字符的固定占位符的处理
  promptString = promptString.replace(/{{\$原咒语\$}}/g, replacedValues['$原咒语$']);
  promptString = promptString.replace(/{{\$上次执行结果\$}}/g, replacedValues['$上次执行结果$']);

  return promptString;
}

export function formatResponseText(text: string, requesting: boolean = false) {
  if (!text) {
    return <p></p>;
  }

  const lineList = text.split('\n').filter(line => line.trim() !== '');

  return (
    <div>
      {lineList.map((line, idx) => {
        if (idx === lineList.length - 1 && requesting) {
          // 最后一行增加，且是请求中，增加loading
          return (
            <p key={idx}>
              {/* {line} <LoadingOutlined /> */}
              {line}
            </p>
          );
        } else {
          return <p key={idx}>{line}</p>;
        }
      })}
    </div>
  );
}

export function generateParamValueDescription(config: SpellConfigType, values: Record<string, string>) {
  let description = [];
  for (let param of config.parameters) {
    if (values[param.name]) {
      description.push(values[param.name]);
    } else {
      description.push('<空>');
    }
  }
  return description.join(',');
}

// 将 <p>、<br> 标签替换为 \n 字符，保留换行信息
export function removeHtmlTags(html: string): string {
  return html.replace(/<p>|<br>/g, '\n').replace(/<[^>]+>/g, '');
}

export function generateRandomString(): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function formatDateTime(date: Date | string): string {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateTimeFromNow(date: Date | string): string {
  return moment(date).fromNow();
}

export function getEnumValueLabelList(types: any, desc: Record<string, string>) {
  let keys: string[] = Object.values(types);

  return keys.map(k => {
    return {
      value: k,
      label: desc[k] || k
    };
  });
}

export function findDuplicates<T>(arr: T[]): T[] {
  const set = new Set<T>();
  const duplicates = new Set<T>();
  for (let i = 0; i < arr.length; i++) {
    const value = arr[i];
    if (set.has(value)) {
      duplicates.add(value);
    } else {
      set.add(value);
    }
  }
  return Array.from(duplicates);
}

export function arrayGroupBy<T, R>(arr: T[], getKey: (obj: T) => string | number, getValue: (obj: T) => R): Record<string | number, R[]> {
  return arr.reduce((acc: Record<string | number, R[]>, obj) => {
    const key = getKey(obj);
    if (!acc[key]) {
      acc[key] = [];
    }
    const value = getValue(obj);
    acc[key].push(value);
    return acc;
  }, {});
}

export function arrayMap<T, R>(arr: T[], getKey: (obj: T) => string | number, getValue: (obj: T) => R): Record<string | number, R> {
  return arr.reduce((acc: Record<string | number, R>, obj) => {
    const key = getKey(obj);
    acc[key] = getValue(obj);
    return acc;
  }, {});
}

// @ts-ignore
export function isControlPressed(event) {
  if (event.metaKey && navigator.platform.indexOf('Mac') !== -1) {
    return true;
  } else if (event.ctrlKey && navigator.platform.indexOf('Win') !== -1) {
    return true;
  }

  return false;
}

export function formatPercent(x: number | undefined | null, y: number | undefined | null) {
  if (x === null || x === undefined || y === null || y === undefined || y === 0) {
    return '-';
  }
  return (100 * x) / y;
}

export function isStrBlank(str: string | null | undefined) {
  if (str === null || str === undefined) {
    return true;
  }
  if (str.trim() === '') {
    return true;
  }
  return false;
}

export function keyByArray<T extends CommonObjectType>(arr: Array<T>, key: string) {
  const ret: Record<string, Array<T>> = {};

  for (const item of arr) {
    const k = item[key];
    if (k in ret) {
      ret[k].push(item);
    } else {
      ret[k] = [item];
    }
  }

  return ret;
}

export function safeJsonParse<T = CommonObjectType>(data: string): CommonObjectType | T {
  try {
    const ret = JSON.parse(data);
    return ret as T;
  } catch (e) {
    return {} as CommonObjectType;
  }
}

export function parseHashUrl(url: string) {
  const hashIndex = url.indexOf('#');
  if (hashIndex < 0) {
    return null;
  }
  const hash = url.substring(hashIndex + 1);
  const index = hash.indexOf('?');
  const route = index >= 0 ? hash.substring(0, index) : hash;
  const paramsStr = index >= 0 ? hash.substring(index + 1) : '';
  const paramsArr = paramsStr.split('&');
  const params: { [key: string]: string } = {};
  for (let i = 0; i < paramsArr.length; i++) {
    const [key, value] = paramsArr[i].split('=');
    if (key) {
      params[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  }
  return {
    route: decodeURIComponent(route),
    params
  };
}

export function groupByToMapValues<T extends object>(dataList: T[], key: string, value: string) {
  const dataGroup = groupBy(dataList, key);
  return mapValues(dataGroup, group => map(group, value));
}

export function toMap<T extends object>(dataList: T[], key: string, value: string) {
  return _.reduce(
    dataList,
    (result: Record<string, T>, item) => {
      // @ts-ignore
      result[item[key]] = item[value];
      return result;
    },
    {}
  );
}

export function toObjMap<T extends object>(dataList: T[], key: string) {
  return _.reduce(
    dataList,
    (result: Record<string, T>, item) => {
      // @ts-ignore
      result[item[key]] = item;
      return result;
    },
    {}
  );
}

export function isSpellUseTab(targetKey: string): boolean {
  return !!targetKey && targetKey.length === 36;
}

export function isSpellEditTab(targetKey: string): boolean {
  const id = decodeURIComponent(targetKey.split('_')[0] || '');
  return !!id && id.length === 36;
}

export const isPosterDesignerTab = (targetKey: string) => {
  return !!targetKey && targetKey.length === 36;
};

export function isDashboardHome(pathname: string): boolean {
  console.log('!!pathname => ', pathname, !!pathname);
  return !!pathname && pathname === '/dashboard/home';
}

export function isHiddenSideMenu(pathname: string): boolean {
  return false;
}

export function isInformationCenter(pathname: string): boolean {
  return !!pathname && pathname === '/new/library';
}

export function downloadFile(fileUrl: string, fileName: string) {
  if (!fileUrl) {
    return;
  }
  // window.open(fileUrl, '_blank');
  if (!fileUrl.startsWith('https') && fileUrl.startsWith('http')) {
    fileUrl = fileUrl.replace('http', 'https');
  }
  // 创建一个<a>标签
  const downloadLink = document.createElement('a');
  // 设置<a>标签的属性
  downloadLink.href = fileUrl;
  // console.log(fileUrl,fileName)
  downloadLink.download = fileName; // 设置文件名称
  // 模拟点击<a>标签，触发文件下载
  downloadLink.click();
}

export function generateOssFileName(originalName: string): string {
  const dotIndex = originalName.lastIndexOf('.');

  let filename = '';
  if (dotIndex > 0) {
    const name = originalName.substring(0, dotIndex) || '';
    const type = originalName.substring(dotIndex);

    filename = name + '_' + new Date().getTime() + type;
  } else {
    filename = originalName + '_' + new Date().getTime();
  }
  return filename;
}

export const cleanTag = (content: string | undefined) => (content ? content.replace(/<[^>]+>/g, '') : '');
