import copy from 'copy-to-clipboard';
import { cloneDeep, sortBy } from 'lodash';
import { MenuItemDTO, MenuItemTreeDTO } from '@/pages/api/menu/types';
import { SpellItemDTO, SpellTreeItem } from '@/pages/api/spell/types';
import { SpellGroupItemDTO } from '@/pages/api/spell-group/types';
import {
  CommonObjectType,
  AiExecuteRequestType,
  MenuItemType,
  SpellConfigParamType,
  SpellConfigParamTypeEnum,
  SpellConfigParamTypeOptionForSelection,
  TreeSelectSpellItem
} from '@/types';
import { UpdateHistoryCopyRequest } from '@/pages/api/spell-execution-history/types';
import { BizTypeEnum } from './constants';
import { AiTestRequest } from '@/pages/api/ai/test';
import { fetchEventSource } from '@microsoft/fetch-event-source';

export const navigate = (path: string | undefined) => {
  if (path === undefined) {
    return;
  }
  window.location.hash = path;
};

export const walk = <T extends { children?: T[] }>(
  nodes: T[] | undefined,
  visitFunc: (record: T, ctx: CommonObjectType, isRoot: boolean) => void | boolean,
  ctx: CommonObjectType,
  isRoot = true
) => {
  if (!nodes?.length) {
    return;
  }
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const ret = visitFunc(node, ctx, isRoot);
    if (ret === false) {
      return;
    }
    walk(node.children, visitFunc, ctx, false);
  }
};

// 父菜单不存在时，子菜单直接过滤掉
export const buildMenuTree = (menus: MenuItemDTO[]): MenuItemTreeDTO[] => {
  const copyMenus = cloneDeep(menus);
  const treeMenus: MenuItemTreeDTO[] = copyMenus.map(menu => ({
    ...menu,
    children: []
  }));

  for (const treeMenu of treeMenus) {
    if (!treeMenu.parentCode) {
      continue;
    }
    const parentMenu = treeMenus.find(menu => menu.code === treeMenu.parentCode);
    if (!parentMenu) {
      continue;
    }
    parentMenu.children.push(treeMenu);
  }

  return treeMenus.filter(menu => !menu.parentCode);
};

export const convertTreeMenuToRoutes = (treeMenus: MenuItemTreeDTO[]): MenuItemType[] => {
  const routes: MenuItemType[] = [];
  for (const treeMenu of treeMenus) {
    const item: MenuItemType = {
      key: treeMenu.code,
      name: treeMenu.name,
      path: treeMenu.url || undefined,
      isHiddenSideMenu: treeMenu.isHiddenSideMenu
    };
    if (treeMenu.children.length) {
      item.children = convertTreeMenuToRoutes(treeMenu.children);
    }
    routes.push(item);
  }

  return routes;
};

type SpellGroupTreeItem = {
  uid: string;
  name: string;
  type: 'SPELL' | 'SPELL_GROUP';
  children: SpellGroupTreeItem[];
  parentUid?: string | null;
};

export const buildSpellGroupTree = (spellsDTO: SpellItemDTO[], spellGroupsDTO: SpellGroupItemDTO[]): SpellGroupTreeItem[] => {
  const copySpellsDTO = sortBy(cloneDeep(spellsDTO), ['sequence']);

  let spellGroupsTree: SpellGroupTreeItem[] = spellGroupsDTO.map(item => ({
    uid: item.uid,
    name: item.name,
    type: BizTypeEnum.SPELL_GROUP,
    children: [],
    parentUid: item.parentUid
  }));

  for (const item of spellGroupsTree) {
    if (!item.parentUid) {
      continue;
    }
    const parentItem = spellGroupsTree.find(obj => obj.uid === item.parentUid);
    if (!parentItem) {
      continue;
    }
    parentItem.children.push(item);
  }
  spellGroupsTree = spellGroupsTree.filter(obj => !obj.parentUid);

  // 填充咒语到咒语库的 children 中
  for (const spell of copySpellsDTO) {
    const pickResult: { spellGroupItem: SpellGroupTreeItem } = {
      spellGroupItem: null
    } as any;
    walk(
      spellGroupsTree,
      (record, ctx) => {
        if (record.uid === spell.groupUid) {
          ctx.spellGroupItem = record;
        }
      },
      pickResult
    );
    if (!pickResult.spellGroupItem) {
      continue;
    }

    pickResult.spellGroupItem.children.push({
      ...spell,
      type: BizTypeEnum.SPELL,
      children: []
    });
  }

  return spellGroupsTree;
};

export const convertSpellsTreeToMenu = (spellGroupsTree: SpellGroupTreeItem[]): MenuItemType[] => {
  const spellGroupsMenu: MenuItemType[] = [];

  for (const item of spellGroupsTree) {
    const menu: MenuItemType = {
      key: item.uid,
      name: item.name,
      children: item.children.map(obj => ({
        key: obj.uid,
        name: obj.name,
        path: `/spells/${obj.uid}`
      }))
    };
    spellGroupsMenu.push(menu);
  }

  return spellGroupsMenu;
};

export const removedSensitiveParams = (params: SpellConfigParamType[]): SpellConfigParamType[] => {
  return params.map(param => {
    if (param.type === SpellConfigParamTypeEnum.Selection) {
      const modifiedTypeOption = JSON.parse(param.typeOption || '[]').map((v: string | SpellConfigParamTypeOptionForSelection) => {
        if (typeof v === 'object') {
          const jsonV: SpellConfigParamTypeOptionForSelection = v;

          return {
            label: jsonV.label,
            value: jsonV.label, // value隐藏起来
            tips: jsonV.tips
          };
        } else {
          return v;
        }
      });

      return {
        ...param,
        typeOption: JSON.stringify(modifiedTypeOption)
      };
    } else {
      return param;
    }
  });
};

export type FetchControllerType = {
  requesting: boolean; // 请求中标志
  reader: ReadableStreamDefaultReader<Uint8Array> | null; // 流读取对象
  controller: AbortController | null; // 请求控制对象
  result: string; // 请求返回结果
  abort: boolean; // 用户中断标志
  done: boolean; // 请求正常结束标志
  error: boolean; // 请求异常标志
  errorMsg?: string; // 错误信息
};

function isErrResponse(str: string) {
  try {
    const ret = JSON.parse(str);
    return ret.success === false;
  } catch (e) {
    return false;
  }
}

export const executeAiFetch = async ({
  fetchController,
  reqData,
  update,
  url
}: {
  fetchController: FetchControllerType;
  reqData: AiExecuteRequestType | AiTestRequest;
  update: () => void;
  url?: string;
}) => {
  // reset map
  fetchController.requesting = true;
  fetchController.controller = new AbortController();
  fetchController.reader = null;
  fetchController.result = '';
  fetchController.abort = false;
  fetchController.done = false;
  fetchController.error = false;
  fetchController.errorMsg = '';

  fetchEventSource(url || '/api/ai/execute', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqData),
    signal: fetchController.controller.signal,
    onmessage: function processText(e): any {
      const { done, value } = e;
      if (done) {
        fetchController.requesting = false;
        fetchController.abort = false;
        fetchController.done = true;
        fetchController.error = false;
        fetchController.errorMsg = '';
        update();
        return;
      }

      const chunkValue = new TextDecoder().decode(value);

      // 后端抛出的业务异常
      if (isErrResponse(chunkValue)) {
        fetchController.requesting = false;
        fetchController.abort = false;
        fetchController.done = false;
        fetchController.error = true;
        fetchController.errorMsg = JSON.parse(chunkValue).error;
        update();
        return;
      }
      // 文本输出为 delta 模式
      fetchController.result += chunkValue;
      // 因为这里直接对对象进行赋值，没有调用 setState 函数，因此需要手动刷新组件，展示最新的结果
      update();
    },
    onclose: function processClose(): any {
      console.log('onclose');
      fetchController.requesting = false;
      fetchController.abort = false;
      fetchController.done = false;
      fetchController.error = false;
      fetchController.errorMsg = '';
      update();
    },
    onerror: function processError(e): any {
      console.log('execute ai fetch onerror', e);
      fetchController.requesting = false;
      fetchController.abort = false;
      fetchController.done = false;
      fetchController.error = true;
      fetchController.errorMsg = e?.toString?.() || JSON.stringify(e);
      update();
    }
  }).catch(e => {
    console.log('catch', e);
    fetchController.requesting = false;
    fetchController.abort = false;
    fetchController.done = false;
    fetchController.error = true;
    fetchController.errorMsg = e?.toString?.() || JSON.stringify(e);
    update();
  });

  // try {
  //   const res = await fetch(url || '/api/ai/execute', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(reqData),
  //     signal: fetchController.controller.signal
  //   });
  //   if (!res.ok || !res.body) {
  //     throw new Error('返回出错了');
  //   }

  //   const reader = res.body.getReader();
  //   fetchController.reader = reader;
  //   reader
  //     .read()
  //     .then(function processText({ done, value }): any {
  //       if (done) {
  //         fetchController.requesting = false;
  //         fetchController.abort = false;
  //         fetchController.done = true;
  //         fetchController.error = false;
  //         fetchController.errorMsg = '';
  //         update();
  //         return;
  //       }

  //       const chunkValue = new TextDecoder().decode(value);

  //       // 后端抛出的业务异常
  //       if (isErrResponse(chunkValue)) {
  //         fetchController.requesting = false;
  //         fetchController.abort = false;
  //         fetchController.done = false;
  //         fetchController.error = true;
  //         fetchController.errorMsg = JSON.parse(chunkValue).error;
  //         update();
  //         return;
  //       }
  //       // 文本输出为 delta 模式
  //       fetchController.result += chunkValue;
  //       // 因为这里直接对对象进行赋值，没有调用 setState 函数，因此需要手动刷新组件，展示最新的结果
  //       update();
  //       return reader.read().then(processText);
  //     })
  //     .catch(() => {
  //       // 用户手动中断
  //       debugger;
  //       fetchController.requesting = false;
  //       fetchController.abort = true;
  //       fetchController.done = false;
  //       fetchController.error = false;
  //       fetchController.errorMsg = '';
  //       update();
  //       return;
  //     });
  // } catch (e: any) {
  //   debugger;
  //   fetchController.requesting = false;
  //   fetchController.done = false;
  //   // 当用户快速中断时，会抛这个异常，也需要定义为中断事件
  //   if (e?.name === 'AbortError') {
  //     fetchController.abort = true;
  //     fetchController.error = false;
  //     fetchController.errorMsg = '';
  //   } else {
  //     fetchController.abort = false;
  //     fetchController.error = true;
  //     fetchController.errorMsg = e?.toString?.() || JSON.stringify(e);
  //   }
  //   update();
  //   return;
  // }

  update();
};

export const stopExecAiFetch = async (fetchController: FetchControllerType, update: () => void) => {
  fetchController.controller?.abort?.();
  // fetchController.reader?.cancel?.();
  fetchController.abort = fetchController.requesting && !fetchController.done && !fetchController.error;
  fetchController.requesting = false;
  update();
};

export const copyRichText = (data: string | undefined | null): boolean => {
  if (data === undefined || data === null) {
    return false;
  }

  // 创建一个div来获取纯文本
  const newDiv = document.createElement('div');
  newDiv.innerHTML = data
    .split('\n')
    .map(v => `<p>${v}</p>`)
    .join('');

  document.body.appendChild(newDiv);
  copy(newDiv.innerText);
  document.body.removeChild(newDiv);

  return true;
};

export const updateHistory = async (historyUid: string, isCopy: boolean) => {
  if (!historyUid) {
    return;
  }
  const req: UpdateHistoryCopyRequest = {
    historyUid,
    isCopy: isCopy
  };

  return fetch('/api/spell-execution-history/updateHistoryCopy', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(req)
  }).then(d => d.json());
};

type spellItem = {
  uid: string;
  name: string;
  groupUid: string;
  sequence: number;
};

type spellGroupItem = {
  uid: string;
  name: string;
  sequence: number;
};

export const genTreeSelectSpellList = (spells: spellItem[], spellGroups: spellGroupItem[]): TreeSelectSpellItem[] => {
  const copySpellGroups = cloneDeep(spellGroups);
  sortBy(copySpellGroups, 'sequence');
  const ret: TreeSelectSpellItem[] = [];

  for (const spellGroup of copySpellGroups) {
    const spellsForGroup = spells.filter(spell => spell.groupUid === spellGroup.uid);
    if (!spellsForGroup.length) {
      continue;
    }

    const item: TreeSelectSpellItem = {
      title: spellGroup.name,
      value: spellGroup.uid,
      bizType: BizTypeEnum.SPELL_GROUP,
      children: spellsForGroup.map(spell => ({
        title: spell.name,
        value: spell.uid,
        bizType: BizTypeEnum.SPELL,
        children: []
      }))
    };
    ret.push(item);
  }

  return ret;
};

export const pickItemFromSpellTree = (spells: SpellTreeItem[] | undefined, value: string): SpellTreeItem | undefined => {
  if (!spells || !spells.length) {
    return;
  }

  for (const spell of spells) {
    if (spell.value === value) {
      return spell;
    }
    const item = pickItemFromSpellTree(spell.children, value);
    if (item) {
      return item;
    }
  }
};

// param1=value1&param2=value2 -> { param1: value1, param2: value2 }
export const parseUrlParams = (paramsStr: string): CommonObjectType | null => {
  if (!paramsStr) {
    return null;
  }
  const ret: CommonObjectType = {};
  const paramItems = paramsStr.split('&');
  for (const paramItem of paramItems) {
    const [key, value] = paramItem.split('=');
    // 无效
    if (!key || !value) {
      continue;
    }

    ret[key] = decodeURIComponent(value);
  }

  return ret;
};

export const hidingThinkTag: (input: string) => { resultText: string; thinkText: string } = (input: string) => {
  if (!input) {
    return { resultText: '', thinkText: '' };
  }
  let output = ''; // 最终显示的输出
  let think = ''; // 思考内容
  let i = 0; // 当前解析位置
  const len = input.length;

  let state = 'normal'; // 状态：normal, tag, hiding, endtag
  let tagBuffer = ''; // 临时存储标签名

  while (i < len) {
    const char = input[i];
    if (state === 'normal') {
      if (char === '<') {
        state = 'tag';
        tagBuffer = '<';
      } else {
        output += char;
      }
    } else if (state === 'tag') {
      tagBuffer += char;
      const tagLower = tagBuffer.toLowerCase();
      if (tagLower === '<think>') {
        // 开始隐藏
        state = 'hiding';
        tagBuffer = '';
        think = '';
      } else if (!'<think>'.startsWith(tagLower)) {
        output += tagBuffer;
        state = 'normal';
        tagBuffer = '';
      }
      // 如果标签未结束，还在tag状态，继续累积
    } else if (state === 'hiding') {
      if (char === '<') {
        state = 'endtag';
        tagBuffer = '<';
      } else {
        think += char;
      }
    } else if (state === 'endtag') {
      tagBuffer += char;
      const tagLower = tagBuffer.toLowerCase();
      if (tagLower === '</think>') {
        // 结束隐藏
        state = 'normal';
        tagBuffer = '';
      } else if (!'</think>'.startsWith(tagLower)) {
        think += tagBuffer;
        state = 'hiding';
        tagBuffer = '';
      }
      // 如果标签未结束，还在tag状态，继续累积
    }
    i++;
  }
  return { resultText: output.trim(), thinkText: think.trim() };
};
